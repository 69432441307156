const primaryColor = ref('1C1C27')
const theme = ref('theme')

export const useEmbedded = () => {
    
    const colorMode = useColorMode()
    const { query: { color, theme } } = useRoute()

    if (color) primaryColor.value = color as string

    if (theme) {
        colorMode.preference = theme === 'dark' ? 'dark' : 'light'

        if (!color)
            primaryColor.value =  theme === 'dark' ? 'F0F0F0' : '212121'

    }
    
    return { primaryColor }
}